@mixin expansion-panel-padding($header-padding-block, $header-padding-inline, $body-padding-inline) {
  .mat-expansion-panel-header {
    padding-inline: $header-padding-inline;
    padding-block: $header-padding-block;
    align-items: normal;
  }
  .mat-expansion-panel-body {
    padding-inline: $body-padding-inline;
  }
}

.myt-expansion-panel.mat-accordion {
  --mat-expansion-header-expanded-state-height: none;
  --mat-expansion-header-collapsed-state-height: none;
  --mat-expansion-header-indicator-color: #{$text-color};
  --mat-expansion-header-text-color: #{$text-color};

  .mat-expansion-panel {
    @include expansion-panel-padding($unit * 4, $unit * 4, $unit * 4);
    box-shadow: none;
    border-radius: 0;
  }

  &.myt-expansion-panel--divider {
    .mat-expansion-panel .mat-expansion-panel-content {
      border-top: 1px solid $neutral-200;
    }
  }

  &.myt-expansion-panel--large {
    .mat-expansion-panel {
      @include expansion-panel-padding($unit * 5, $unit * 6, $unit * 6);
    }
  }
  &.myt-expansion-panel--highlight {
    .mat-expansion-panel {
      background-color: $accent-100;
      border: 2px solid $accent-400;
      border-radius: 0%;
    }
  }

  &.myt-expansion-panel--primary {
    .mat-expansion-panel {
      --mat-expansion-container-background-color: rgba(255, 255, 255, 0.07);
      --mat-expansion-container-shape: #{$unit * 2};
      .mat-expansion-panel-header {
        --mat-expansion-header-indicator-color: #{$neutral-300};
        .mat-expansion-panel-header-title {
          --mat-expansion-header-text-color: #{$primary-300};
        }
        &:focus,
        &:hover {
          background: none;
        }
      }
    }
  }
}
